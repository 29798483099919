<template>
    <div class="betinfoliks">
        <router-link tag="button" to="/sports_betinfo" :class="{'active':currentIndex == 1}" @click.native="changeIndex(1)"> 스포츠</router-link>
        <router-link to="/leisure_betinfo" tag="button" :class="{'active':currentIndex == 2}" @click.native="changeIndex(2)">미니게임</router-link>
        <router-link to="/honorcasino_betinfo" tag="button" :class="{'active':currentIndex == 3}" @click.native="changeIndex(3)">카지노</router-link>
        <!--        <router-link to="/thirdpartgame_betinfo" tag="button" @click.native="changeIndex(4)">Tgame365</router-link>-->

        <!--                        <router-link class="bw4" to="/reachcasino_betinfo" tag="button">Reach 카지노</router-link>-->
    </div>
</template>

<script>

    export default {
        name: "BetInfoLinks",
        data() {
            return {
                currentIndex: 1,
            }
        },
        methods:{
            changeIndex(idx){
                this.currentIndex = idx;
                console.log(this.currentIndex)
            }
        }
    }
</script>

<style scoped>
    .betinfoliks {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        box-sizing: border-box;
        padding: 5px 0;
        margin-bottom: 10px;
    }

    .betinfoliks button {
        width: 33%;
        border-radius: 5px;
        height: 32px;
        background: linear-gradient(180deg, var(--minigamecompbuttonbg01), var(--minigamecompbuttonbg02));
        color: #353539;
    }
    .active{
        --border: 1px solid #c9c317;
    }

    @media screen and (max-width: 1024px) {
        .betinfoliks button {
            width: 33.1%;
        }
    }

</style>